<div class="footer">
  <div class="footer_box contentCenter" >
    <div class="footer_box_top" fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxFlex="100" fxFlex.gt-sm="24"><a routerLink="/" class="logo_footer" aria-label="Home"></a></div>
      <div fxFlex="100" fxFlex.gt-sm="66" fxLayout="row wrap" fxLayoutAlign="space-between start">
        <div fxFlex="100" fxFlex.gt-sm="26">
          <p class="footer_box_h indt" i18n="footer|footer header 1">QUICK LINKS</p>
          <p class="footer_box_txt"><a href="{{pcUrl}}/registration.html" target="_blank" rel="nofollow noreferrer" i18n-aria-label="footer link 1|@@footer_link1" aria-label="Real Account" i18n="@@footer_link1">Real Account</a></p>
          <p class="footer_box_txt"><a routerLink="/platforms.html" i18n-aria-label="footer link 2|@@footer_link2" aria-label="MT4 Platform" i18n="@@footer_link2">MT4 Platform</a></p>
          <p class="footer_box_txt"><a routerLink="/tutorials.html" i18n-aria-label="footer link 3|@@footer_link3" aria-label="Tutorials" i18n="@@footer_link3">Tutorials</a></p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="40">
          <p class="footer_box_h indt" i18n="footer|footer header 2" >KUWAIT (MAIN OFFICE):</p>
          <p class="footer_box_txt" i18n="footer|footer txt 1">City Tower ( Al Madina Tower) Floor 16</p>
          <p class="footer_box_txt" i18n="footer|footer txt 2">Khalid Ibn Al Waleed Street Sharq Kuwait</p>
          <p class="footer_box_txt" i18n="footer|footer txt 3">P.O.BOX 26635, SAFAT 13127</p>

          <p class="footer_box_h m_t_mob" i18n="footer|footer header 3">KUWAIT (JAHRA BRANCH):</p>
          <p class="footer_box_txt" i18n="footer|footer txt 4">Jahra - a piece (93) - building (27)</p>
          <p class="footer_box_txt" i18n="footer|footer txt 5">first floor Office No. (9 \ 10 \ 11)</p>
        </div>
        <div fxFlex="100" fxFlex.gt-sm="34">
          <p class="footer_box_h indt" i18n="footer|footer header 4">CONTACT US</p>
          <div class="width200">
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
              <p class="footer_box_txt" i18n="footer|footer txt 6">Phone:</p>
              <p class="footer_box_txt" i18n="footer|footer txt 7">+965 - 22020490</p>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
              <p class="footer_box_txt" i18n="footer|footer txt 8">Dealing Desk:</p>
              <p class="footer_box_txt" i18n="footer|footer txt 9">+965 - 22431418</p>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
              <p class="footer_box_txt" i18n="footer|footer txt 10"> Fax:</p>
              <p class="footer_box_txt" i18n="footer|footer txt 11">+965 - 22438896</p>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between start">
              <p class="footer_box_txt" i18n="footer|footer txt 12">E-mail:</p>
              <p class="footer_box_txt"><a href="mailto:admin@swissfs.com">admin@swissfs.com</a></p>
            </div>
            <div fxLayout="row wrap" fxLayoutAlign="space-between center">
              <a href="https://twitter.com/Swissfs" class="social_btn ico_twitter" target="_blank" rel="nofollow noreferrer" i18n-aria-label="footer link 4|@@footer_link4" aria-label="twitter" i18n="@@footer_link4"></a>
             <!-- <a href="" class="social_btn ico_youtube" target="_blank" rel="nofollow"></a>-->
              <a href="https://www.facebook.com/Swissfs" class="social_btn ico_facebook" target="_blank" rel="nofollow noreferrer" i18n-aria-label="footer link 5|@@footer_link5" aria-label="facebook" i18n="@@footer_link5"></a>
              <a href="https://instagram.com/swiss.fs" class="social_btn ico_instagram" target="_blank" rel="nofollow noreferrer" aria-label="instagram"></a>
              <a href="https://www.linkedin.com/company/swissfskw" class="social_btn ico_linkedin" target="_blank" rel="nofollow noreferrer" i18n-aria-label="footer link 6|@@footer_link6" aria-label="linkedin" i18n="footer_link6"></a>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="footer_box_btm"  fxLayout="row wrap" fxLayoutAlign="space-between start">
      <div fxFlex="100" fxFlex.gt-sm="48">
        <p class="footer_box_txt p_l p_t footer_box_btm_img img_risk" i18n="footer|footer risk"><strong>Risk Warning</strong>: trading foreign exchange on margin carries a high level of risk, and may not be suitable for all investors. Read our <a routerLink="/company.html" fragment="risk-disclosure" style="text-decoration: underline">Risk Disclosure</a>.</p>
      </div>
      <div class="footer_m_t_mob" fxFlex="100" fxFlex.gt-sm="48" fxLayout="row wrap" fxLayoutAlign="space-between start">

        <div fxFlex="100" fxFlex.gt-sm="100">
          <p class="footer_box_txt p_l p_t footer_box_btm_img img_intl" i18n="footer|footer swiss intl">Swiss for Direct Trading in Currencies, Metals and Commodities is licensed from Ministry of Commerce and Industry of Kuwait, License number 89468/2002</p>
        </div>

    </div>
      <div class="footer_m_t_mid" fxFlex="100">
        <p class="footer_box_txt">Disclaimer:</p>

        <p class="footer_box_txt m_t_mob_s">The information on this website is not targeted at residents of the United States, or other specific countries and is not intended for distribution to, or use by any person in any country or jurisdiction where such distribution or use would be contrary to applicable law or regulation</p>

      </div>


  </div>

</div>
</div>


