import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {ErrorComponent} from '@view/general/error/error.component';
import {WebLayoutComponent} from '@view/layout/web-layout/web-layout.component';
import {HomeComponent} from '@view/web/home/home.component';
import {CompanyComponent} from '@view/web/company/company.component';
import {BonusComponent} from '@view/web/bonus/bonus.component';
import {ProductsComponent} from '@view/web/products/products.component';

import {PlatformsComponent} from '@view/web/platforms/platforms.component';
import {TutorialsComponent} from '@view/web/tutorials/tutorials.component';
import {ForPartnersComponent} from '@view/web/for-partners/for-partners.component';

import {ContactUsComponent} from '@view/web/contact-us/contact-us.component';
import {CallBackComponent} from '@view/web/call-back/call-back.component';
import {BlogComponent} from '@view/web/blog/blog.component';
import {Blog2Component} from '@view/web/blog2/blog2.component';
import {NewsOneComponent} from '@view/web/news-one/news-one.component';


const AppRoutes: Routes = [
  {
    path: '',
    component: WebLayoutComponent,
    data: {
      menu: 'SwissFS',
      title: 'SwissFS',
      description: 'SwissFS',
    },
    children: [
      {
        path: '',
        component: HomeComponent,
        data: {
          menu: $localize`:menu|name 1:Home`,
          title: $localize`:menu|name 1 title:SwissFS — Home`,
          description: $localize`:menu|name 1 description:SwissFS — Home`,
        }
      },
      {
        path: 'company.html',
        component: CompanyComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 2:The Company`,
          title: $localize`:menu|name 2 title:SwissFS — The Company`,
          description: $localize`:menu|name 2 description:SwissFS — The Company`,
        }
      },
      {
        path: 'bonus.html',
        component: BonusComponent, pathMatch: 'full',
        data: {
          /*menu: 'Bonus',*/
          title: $localize`:menu|name 3 title:SwissFS — Bonus`,
          description: $localize`:menu|name 3 description:SwissFS — Bonus`,
        }
      },
      {
        path: 'blog.html',
        component: BlogComponent, pathMatch: 'full',
        data: {
          title: $localize`:menu|name 3 title:SwissFS — Blog`,
          description: $localize`:menu|name 3 description:SwissFS — Blog`,
        }
      },
      {
        path: 'blog2.html',
        component: Blog2Component, pathMatch: 'full',
        data: {
          title: $localize`:menu|name 3 title:SwissFS — Blog`,
          description: $localize`:menu|name 3 description:SwissFS — Blog`,
        }
      },
      {
        path: 'blog/:page',
        component: NewsOneComponent, pathMatch: 'full',
        data: {
          title: $localize`:menu|title 5:SwissFS — Blog`,
          description: $localize`:menu|description 5:SwissFS — Blog`,
        }
      },
      {
        path: 'products.html',
        component: ProductsComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 4:Products`,
          title: $localize`:menu|name 4 title:SwissFS — Products`,
          description: $localize`:menu|name 4 description:SwissFS — Products`,
        }
      },
      {
        path: 'platforms.html',
        component: PlatformsComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 5:Platforms`,
          title: $localize`:menu|name 5 title:SwissFS — Platforms`,
          description: $localize`:menu|name 5 description:SwissFS — Platforms`,
        }
      },
      {
        path: 'forpartners.html',
        component: ForPartnersComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 6:For Partners`,
          title: $localize`:menu|name 6 title:SwissFS — For partners`,
          description: $localize`:menu|name 6 description:SwissFS — For partners`,
        }
      },
      {
        path: 'tutorials.html',
        component: TutorialsComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 7:Tutorials`,
          title: $localize`:menu|name 7 title:SwissFS — Tutorials`,
          description: $localize`:menu|name 7 description:SwissFS — Tutorials`,
        }
      },
      {
        path: 'contact-us.html',
        component: ContactUsComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 8:Contact Us`,
          title: $localize`:menu|name 8 title:SwissFS — Contact Us`,
          description: $localize`:menu|name 8 description:SwissFS — Contact Us`,
        }
      },
      {
        path: 'callback.html',
        component: CallBackComponent, pathMatch: 'full',
        data: {
          menu: $localize`:menu|name 9:Call Back`,
          title: $localize`:menu|name 9 title:SwissFS — Call Back`,
          description: $localize`:menu|name 9 description:SwissFS — Call Back`,
        }
      },
      {path: 'error', component: ErrorComponent},
      {path: '**', component: ErrorComponent} // otherwise redirect to ErrorComponent

    ]
  }
];

// export class PreloadModules implements PreloadingStrategy {
//
//   preload(route: Route, load: () => Observable<boolean>): Observable<boolean> {
//     if (route.data && route.data.noPreLoad) {
//       return of(null);
//     }
//     return of(true).pipe(delay(2000), switchMap((_: boolean) => load()));
//   }
//
// }
// ExtraOptions - https://angular.io/api/router/ExtraOptions
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {
    //  preloadingStrategy: PreloadModules,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy',
    initialNavigation: 'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRouting {
}

