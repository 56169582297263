import {NgModule} from '@angular/core';

import {SharedModule} from '@shared/shared.module';
import {AppFormModule} from '@view/form/appForm.module';

import { HomeComponent } from '@view/web/home/home.component';
import { CompanyComponent } from './company/company.component';
import { BonusComponent } from './bonus/bonus.component';
import { CallBackComponent } from './call-back/call-back.component';
import {ViewModule} from '@view/view.module';
import { ProductsComponent } from './products/products.component';

import { PlatformsComponent } from './platforms/platforms.component';
import { TutorialsComponent } from './tutorials/tutorials.component';
import { ForPartnersComponent } from './for-partners/for-partners.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import { ContactUsComponent } from './contact-us/contact-us.component';

import {NewsComponent} from '@view/widget/news/news.component';
import {BlogComponent} from '@view/web/blog/blog.component';
import {Blog2Component} from '@view/web/blog2/blog2.component';
import {NewsOneComponent} from '@view/web/news-one/news-one.component';

@NgModule({
  declarations: [
    HomeComponent,
    CompanyComponent,
    ProductsComponent,
    PlatformsComponent,
    TutorialsComponent,
    ForPartnersComponent,
    ContactUsComponent,
    BonusComponent,
    BlogComponent,
    Blog2Component,
    CallBackComponent,
    NewsComponent,
    NewsOneComponent
  ],
    imports: [
        SharedModule,
        AppFormModule,
      //  DragDropModule,
      //  MatExpansionModule,
        ViewModule,
      MatPaginatorModule
    ]
})
export class WebModule {
}
